<template>
  <div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="报名大图">
        <Upload-img v-model="ruleForm.enter_img"></Upload-img>
      </el-form-item>
      <el-form-item label="关于我们">
        <Upload-img v-model="ruleForm.our_img"></Upload-img>
      </el-form-item>
      <el-form-item label="兴趣测试">
        <Upload-img v-model="ruleForm.inter_img"></Upload-img>
      </el-form-item>

      <el-row>
        <el-col :span="10">
          <el-form-item label="一码标题">
            <el-input v-model="ruleForm.first_title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="一码">
        <Upload-img
          v-model="ruleForm.first_img"
          width="265px"
          height="265px"
        ></Upload-img>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item label="二码标题">
            <el-input v-model="ruleForm.sec_title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="二码">
        <Upload-img
          v-model="ruleForm.sec_img"
          width="265px"
          height="265px"
        ></Upload-img>
      </el-form-item>

      <!-- <el-form-item label="报名开放时间">
        <el-date-picker
          v-model="ruleForm.open_time"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          type="date"
          placeholder="Pick a day"
        />
      </el-form-item> -->

      <el-row>
        <el-col :span="10">
          <el-form-item label="报名开放时间">
            <el-date-picker
              v-model="ruleForm.in_time"
              type="datetimerange"
              unlink-panels
              range-separator="到"
              start-placeholder="Start date"
              end-placeholder="End date"
              format="YYYY/MM/DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="联系信息">
        <div style="border: 1px solid #ccc; z-index: 1001">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 500px; overflow-y: hidden"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
          />
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
        <el-button @click="onAboutUs()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount, shallowRef } from 'vue'
import { aboutUsAPI, saveDetailsAPI } from '@/api/about-us'
import { ElMessage } from 'element-plus'
import UploadImg from '@/components/UploadImg'
import { userStore } from '@/store/index'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

/** 副文本 S */
const store = userStore()

const uploadImage = 'uploadImage'

const mode = 'default' // 或 'simple'

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

// 内容 HTML
const valueHtml = ref('')

// 工具栏配置
const toolbarConfig = {}

// 编辑器配置
const editorConfig = { placeholder: '请输入内容...', MENU_CONF: {} }
editorConfig.MENU_CONF[uploadImage] = {
  fieldName: 'file',
  // server: '/v1/img_upload',
  server: 'http://lnjsxy.college.yslts.com/v1/img_upload',
  // 自定义增加 http  header
  headers: {
    Authorization: `Bearer ${store.token}`
  },

  // 图片上传之自定义插入
  customInsert(res, insertFn) {
    const url = res.data.url
    insertFn(url)
  }
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

// 编辑器创建时进行赋值
const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}

/** 副文本 E */

/** 详情 S */
const onAboutUs = async () => {
  const data = await aboutUsAPI()
  ruleForm.value = data
  valueHtml.value = data.detail
}
onAboutUs()
/** 详情 E */

const ruleForm = ref({
  id: '',
  enter_img: '',
  inter_img: '',
  our_img: '',
  first_title: '',
  first_img: '',
  sec_title: '',
  sec_img: '',
  detail: '',
  open_time: '',
  in_start_time: '',
  in_end_time: '',
  in_time: ''
})

const rules = ref({})

// 获取 ref 实例
const ruleFormRef = ref(null)
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      try {
        delete ruleForm.value.in_time
        ruleForm.value.detail = valueHtml.value
        await saveDetailsAPI(ruleForm.value)
        ElMessage.success('信息修改成功')
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

watch(
  () => ruleForm.value.in_time,
  (val) => {
    if (!val) return
    ruleForm.value.in_start_time = val[0]
    ruleForm.value.in_end_time = val[1]
  },
  {
    deep: true
  }
)
</script>

<style lang="scss" scoped></style>
